body{
    /* background-color:#2e2e2e; */
}
.teamPic {
    width: 50vw;
    border-radius: 30px;
    display: block;
    margin: 0 auto;
}

.records {
    margin-bottom: 20px;
}

.container {
    width: 90%;
}


.divider {
    margin-bottom: 50px;
}

/* Note styles */
p strong {
    color: #333;
    font-weight: bold;
    margin-top: 30px;
    line-height: 2;
}

p {
    color: #333;
    line-height: 2.25;
}

.tabs li a:active {
    background-color: none;
}

/* Styles for small mobile devices (up to 1000px) */
@media only screen and (max-width: 1000px) {
    body {
        margin-top: 30px;
        font-size: 18px;
        width:90%;

    }

    .teamPic {
        width: 100%;
        height: auto;
        border-radius: 0;
    }
}
