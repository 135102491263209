.image-gallery-slide img {
    max-width: 50vw; 
    height: auto; 
}

.teamPic-container {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #171717;
    z-index: -1;
}

.image-gallery-description {

    position: fixed;
    bottom: 20px !important;
    left: 0;
    width: 100%;
    background: transparent !important;
}



    .image-gallery-description p {

        border-radius: 40px;
        font-weight: bold;
        font-size: 22px;
        background-color: #0f0f0f;
        color: #ffffff !important;
    }
    .image-gallery-description .no-bold {

        font-weight: none !important;
      
    }

.image-gallery-left-nav,
.image-gallery-right-nav {
    top: 50px; 
}

.image-gallery-left-nav {
    margin-left: -50% !important;
}

.image-gallery-right-nav {
    margin-right: -50% !important;
}

.iframe{
    width: 100%;
}


.button {
    border-radius: 20px;
    background-color: red;
    border: none;
    color: #FFFFFF;
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    padding: 7px;
    width: 20%;
    transition: all 0.5s;
    cursor: pointer;
    margin-bottom: 20px;
}

    .button span {
        cursor: pointer;
        display: inline-block;
        position: relative;
        transition: 0.3s;
    }

        .button span:after {
            content: '\00bb';
            position: absolute;
            opacity: 0;
            top: 0;
            right: -20px;
            transition: 0.5s;
        }

    .button:hover span {
        padding-right: 25px;
    }

        .button:hover span:after {
            opacity: 1;
            right: 0;
        }

    .Home-container{
        /* background: linear-gradient(to top right, #fff152 0%, #ff5252 100%); */
        /* old color: linear-gradient(to top right, #b3b300 0%, #cc0000 100%); */
        background-repeat: no-repeat;
      
    }


@media only screen and (max-width: 1000px) {
    .image-gallery-slide img {
        max-width: 100%;
        height: auto;
    }

    .image-gallery-left-nav {
        margin-left: 0 !important;
    }

    .image-gallery-right-nav {
        margin-right: 0 !important;
    }

    .button{
        width: 40%;
    }

}



@media (max-width: 1000px) {
    .flex.flex-row {
        flex-direction: column-reverse;
    }

    .align-left {
        text-align: center;
    }

    .image-gallery-description p {
        font-size: 15px;
    }
    
    
}

