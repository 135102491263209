
.bio-card {
    border-radius: 30px;
    border-width: 0.125rem;
    border-color: #fff;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: row;
}

.about-us-container {
    margin: 0 auto;
    padding: 20px;
    margin-top: 50px;
}

@media only screen and (max-width: 1000px) {
    .about-us-container {
        max-width: 100%;
    }

    .bio-card {
        flex-direction: column;
    }
}