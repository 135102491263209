.star {
  display: inline-block;
}



input,
select {
  margin-top: 5px;
  margin-bottom: 5px;
}

span.line {
  display: inline-block;
}
