.fredHalfPic {
    width: 55vw;
    border-radius: 30px;
    display: block;
    margin: 0 auto;
} 
  
@media only screen and (max-width: 1000px){
    .fredHalfPic {
        width: 100%;
        height: auto;
        border-radius: 0;
    }
}
body{
    /* background-color: #e21833; */
}