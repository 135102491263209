.App-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	border-bottom: 1px solid rgba(255,255,255, 0.3);
	z-index: 999;
}

.tab {
	float: right;
	width: 110px;
}

.home {
	float: left;
	width: 32%;
	user-select: none;
}

.tab a {
	display: block;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
	font-weight: bold;
}
	.tab a:hover:not(.no-highlight) {
		text-decoration: underline red 3px;
		text-underline-offset: 10px; 
		}

	.tab.active:not(.no-highlight) {
		text-decoration: underline red 3px;
		color: red;
		text-underline-offset: 10px; 
	}

.no-highlight a:hover {
	text-decoration: transparent !important;
}

.tab ul.dropdown {
	width: 110px;
	background-color: #171717;
	position: absolute;
	z-index: 999;
	display: none;
}

.tab:hover ul.dropdown {
	display: block;
}

/*mobile scroll menu*/
div.scrollmenu {
	overflow: auto;
	white-space: nowrap;
}

	div.scrollmenu a {
		display: inline-block;
		text-align: center;
		padding: 12px;
		text-decoration: none;
	}

	div.scrollmenu a:hover {
		text-decoration: underline red 3px;
		text-underline-offset: 10px;
	}


.MobileHome{
	margin-top: 3%;
	margin-bottom: 3%;
	margin-left: 3%;
}

/*Hamburger menu stuff*/
.container {
	display: inline-block; 
	cursor: pointer;
}

.bar1, .bar2, .bar3 {
	margin: 6px 0;
	transition: 0.4s;
}

.change .bar1 {
	transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {
	opacity: 0;
}

.change .bar3 {
	transform: translate(0, -11px) rotate(45deg);
}

/*hide traditional tabs on mobile*/
@media (max-width: 970px) {
	.tabs-container .tabs {
		display: none;
	}
}
